<script lang="ts">
    import { slide } from "svelte/transition";

    export let list:any = [];

    const toggle = function(index) {
        list.forEach((item, currentIndex) => {
            if (currentIndex === index) {
                return;
            }

            item.isToggled = false;
        });

        list[index].isToggled = !list[index].isToggled;
    };
</script>
<style>
    .fa-angle-right { transition: all 0.2s ease-in-out; }

    [aria-expanded=true] .fa-angle-right { transform: rotate(0.25turn); }
</style>

{#each list as item, index }
    <button on:click={() => toggle(index)} aria-expanded={item.isToggled}>
        <i class="fa-solid fa-notes-medical"></i>
        <span class="question">
            {@html item.question}
        </span>
        <i class="fa-solid fa-angle-right"></i>
    </button>
    {#if item.isToggled}
        <div class="collapsible" transition:slide={{ duration: 250 }}>
            {@html item.answer}
        </div>
    {/if}
{/each}